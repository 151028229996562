<template>
   <div>
      <div class="card-body">
         <p class="login-box-msg" style="text-align: justify; font-size: 12px">Após digitar seu email no campo abaixo e clicar no botão enviar, um link de redefinição de senha será enviado para o seu email.</p>

         <form @submit.prevent="pwdRecovery">
            <div class="input-group mb-3">
               <input type="email" class="form-control" placeholder="Email" v-model="email" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-envelope"></span>
                  </div>
               </div>
            </div>
            <div class="row">
               <!-- /.col -->
               <div class="col-12">
                  <button
                     type="submit"
                     class="btn btn-outline-primary btn-block"
                     :disabled="isLoading">
                     <b>Enviar</b>
                  </button>
               </div>
               <!-- /.col -->
            </div>
         </form>
      </div>
      <!-- /.card-body -->

   </div>
</template>

<script>
import { mapActions } from "vuex";
import { useToast } from "vue-toastification";

export default {
   name: "ForgotPassword",
   setup() {
      const toast = useToast()
      return { toast }
   },
   data(){
      return {
         email: '',
         isLoading: false
      }
   },
   methods: {
      ...mapActions(['passwordRecovery']),
      async pwdRecovery () {
         this.isLoading = true;

         try {
            const result = await this.passwordRecovery(this.email);

            if (!result)
               return this.toast.error(this.$t("the_account_associated_with_this_email_was_not_found"));

            this.toast.success(this.$t("password_reset_email_sent_successfully"));
         } catch (e) {
            this.toast.error(this.$t("password_reset_email_sent_error"));
         } finally {
            this.isLoading = false;
         }
      }
   }
}
</script>
